import { useState, useMemo, useEffect } from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useSelector } from "react-redux";
import AddToPlaylist from "../../../components/AddToPlaylist";
import { GridWrapper } from "../../../components/Grid";
import Header from "../../../components/Header";
import Loader from "../../../components/Loader";
import { DEFAULT_PLAYLIST_NB } from "../../../components/PlaylistDialog/PlaylistDialog";
import TitleCard from "../../../components/TitleCard";
import { api } from "../../../services/apis/api";
import { PlaylistContext } from "../../../services/redux/modules/playlist/types";
import { selectRawIntervalDetail } from "../../../services/redux/modules/user/selector";
import { Track as TrackType } from "../../../services/types";
import Track from "./Track";
import TrackHeader from "./Track/TrackHeader";
import s from "./index.module.css";
import { Album, Artist } from "../../../services/types";
import { presetIntervals, PresetIntervalDetail, SpotifyTimeRange } from "../../../services/intervals";
import { SpotifyTimeRangeSelector } from "../../../components/SpotifyTimeRangeSelector/SpotifyTimeRangeSelector";

type RangeItem = {
  track: TrackType;
  album: Album;
  artists: Artist[];
  rank: number;
};

const songTimeLabels: Record<SpotifyTimeRange, string> = {
  short_term: "Fresh Finds 🌱",
  medium_term: "Steady Grooves 💫",
  long_term: "Timeless Tracks 💝"
};

export default function Songs() {
  const { interval, unit } = useSelector(selectRawIntervalDetail);
  const [timeRange, setTimeRange] = useState<SpotifyTimeRange>("short_term");
  const [items, setItems] = useState<RangeItem[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchSongs = async () => {
      setLoading(true);
      try {
        const response = await api.getSpotifyTopSongs(timeRange);
        const transformedItems: RangeItem[] = response.data.map((item, index) => ({
          track: item.track,
          album: item.album,
          artists: [item.artist],
          rank: index + 1
        }));
        setItems(transformedItems);
      } catch (error) {
        console.error('Failed to fetch songs:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSongs();
  }, [timeRange]);

  const currentPreset = useMemo(() => 
    presetIntervals.find((p) => p.unit === unit) as typeof presetIntervals[number] | undefined,
    [unit]
  );

  const context = useMemo<PlaylistContext>(
    () => ({
      type: "top",
      nb: DEFAULT_PLAYLIST_NB,
      interval: {
        start: interval.start.getTime(),
        end: interval.end.getTime(),
      },
    }),
    [interval.end, interval.start],
  );

  return (
    <div>
      <Header
        title="Top songs"
        subtitle="The tracks you've been obsessing over (we won't judge... much 😏)"
        right={
          <SpotifyTimeRangeSelector
            value={timeRange}
            onChange={setTimeRange}
            timeRangeLabels={songTimeLabels}
          />
        }
        timeSelectorType="none"
      />
      <div className={s.content}>
        <TitleCard
          noBorder
          title="Top songs"
          right={<AddToPlaylist context={context} />}>
          {loading ? (
            <Loader />
          ) : (
            <GridWrapper>
              <TrackHeader />
              {items.map(item => (
                <Track
                  playable
                  key={item.track.id}
                  track={item.track}
                  album={item.album}
                  artists={item.artists}
                  duration={item.track.duration_ms}
                  totalDuration={item.track.duration_ms}
                />
              ))}
            </GridWrapper>
          )}
        </TitleCard>
      </div>
    </div>
  );
}
