import {
    SelectProps,
    FormControlLabel,
    Radio,
    RadioGroup,
    Select,
    MenuItem,
  } from "@mui/material";
  import React, { useCallback, useMemo } from "react";
  import { useMobile } from "../../services/hooks/hooks";
  import { SpotifyTimeRange } from "../../services/intervals";
  import Text from "../Text";
  import s from "./index.module.css";
  
  interface SpotifyTimeRangeSelectorProps {
    value: SpotifyTimeRange;
    onChange: (newRange: SpotifyTimeRange) => void;
    selectType?: SelectProps["variant"];
    forceTiny?: boolean;
    timeRangeLabels?: Record<SpotifyTimeRange, string>;
  }
  
  const defaultTimeRangeLabels: Record<SpotifyTimeRange, string> = {
    short_term: "Short term",
    medium_term: "Medium term",
    long_term: "Long term",
  };
  
  const TIME_RANGES: SpotifyTimeRange[] = ['short_term', 'medium_term', 'long_term'];
  
  export function SpotifyTimeRangeSelector({
    value,
    onChange,
    selectType,
    forceTiny,
    timeRangeLabels = defaultTimeRangeLabels,
  }: SpotifyTimeRangeSelectorProps) {
    const upmd = !useMobile()[1] && !forceTiny;
  
    const existingRange = useMemo(
      () => TIME_RANGES.findIndex(range => range === value),
      [value]
    );
  
    const internOnChange = useCallback(
      (index: number) => {
        const range = TIME_RANGES[index];
        if (range) {
          onChange(range);
        }
      },
      [onChange]
    );
  
    let content: React.ReactNode;
  
    if (!upmd) {
      content = (
        <Select
          variant={selectType}
          value={existingRange}
          onChange={(ev) => internOnChange(ev.target.value as number)}
        >
          {TIME_RANGES.map((range, index) => (
            <MenuItem key={range} value={index}>
              {timeRangeLabels[range]}
            </MenuItem>
          ))}
        </Select>
      );
    } else {
      content = (
        <div className={s.radiogroup}>
          <RadioGroup
            row
            value={existingRange}
            onChange={(ev) => internOnChange(Number(ev.target.value))}
            name="time-range-radio-group"
          >
            {TIME_RANGES.map((range, index) => (
              <FormControlLabel
                key={range}
                value={index}
                control={<Radio />}
                label={<Text>{timeRangeLabels[range]}</Text>}
              />
            ))}
          </RadioGroup>
        </div>
      );
    }
  
    return <>{content}</>;
  }