import { ReactNode } from "react";
import {
  Home,
  HomeOutlined,
  BarChart,
  BarChartOutlined,
  MusicNote,
  MusicNoteOutlined,
  Album,
  AlbumOutlined,
  Person,
  PersonOutlined,
  Settings,
  SettingsOutlined,
  ExitToApp,
  Share,
  ShareOutlined,
  Speed,
  SpeedOutlined,
  Insights,
  InsightsOutlined,
  LocalFireDepartment,
  LocalFireDepartmentOutlined,
} from "@mui/icons-material";

export interface SiderLink {
  label: string;
  link: string;
  icon: ReactNode;
  iconOn: ReactNode;
  restrict?: "guest" | "admin";
}

export interface SiderCategory {
  label: string;
  items: SiderLink[];
}

export const links: SiderCategory[] = [
  {
    label: "Musical Soul Journey",
    items: [
      {
        label: "Your Musical Soul Map",
        link: "/",
        icon: <InsightsOutlined />,
        iconOn: <Insights />,
      },
      {
        label: "Roast My Playlist 🔥",
        link: "/roast",
        icon: <LocalFireDepartmentOutlined />,
        iconOn: <LocalFireDepartment />,
      },
      {
        label: "Earworm Hall of Fame",
        link: "/top/songs",
        icon: <MusicNoteOutlined />,
        iconOn: <MusicNote />,
      },
      {
        label: "Your Musical Timeline",
        link: "/home",
        icon: <HomeOutlined />,
        iconOn: <Home />,
      },
      {
        label: "Music Nerd Stats",
        link: "/all",
        icon: <BarChartOutlined />,
        iconOn: <BarChart />,
      },
      {
        label: "Your Musical Heroes",
        link: "/top/artists",
        icon: <PersonOutlined />,
        iconOn: <Person />,
      }
    ]
  },
  {
    label: "Musical Connections",
    items: [
      {
        label: "Soul Match Finder",
        link: "/collaborative/affinity",
        icon: <MusicNoteOutlined />,
        iconOn: <MusicNote />,
        restrict: "admin",
      },
    ],
  },
  {
    label: "Backstage Pass",
    items: [
      {
        label: "Share the Rhythm",
        link: "/share",
        icon: <ShareOutlined />,
        iconOn: <Share />,
      },
      {
        label: "Sound Check",
        link: "/settings/account",
        icon: <SettingsOutlined />,
        iconOn: <Settings />,
      },
      {
        label: "End Concert",
        link: "/logout",
        icon: <ExitToApp />,
        iconOn: <ExitToApp />,
      },
    ],
  },
];
