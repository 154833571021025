import { useSelector } from "react-redux";
import { selectUser } from "../../services/redux/modules/user/selector";
import { DateFormatter } from "../../services/date";
import Text from "../Text";
import { InfoOutlined } from "@mui/icons-material";
import { Tooltip as MuiTooltip } from "@mui/material";
import s from "./index.module.css";

export default function ImportNotice() {
  const user = useSelector(selectUser);
  
  if (!user?.firstListenedAt) {
    return null;
  }

  const firstDate = new Date(user.firstListenedAt);
  const today = new Date();
  const diffTime = Math.abs(today.getTime() - firstDate.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  // Get the current domain
  const domain = window.location.origin;

  return (
    <div className={s.notice}>
      <Text>
        Data imported from{" "}
        <Text element="strong">
          {DateFormatter.listenedAt(firstDate)}
        </Text>
        <Text element="span" className={s.daysAgo}>
          ({diffDays} days of listening history)
          {diffDays < 30 && (
            <MuiTooltip
              title={
                <span>
                  Spotify only allows limited listening history to be downloaded by default. 
                  To get your full history, request your data from{" "}
                  <a 
                    href="https://www.spotify.com/account/privacy/" 
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: 'inherit' }}
                  >
                    Spotify Privacy
                  </a>
                  , then upload it in the{" "}
                  <a 
                    href={`${domain}/settings/account`}
                    style={{ color: 'inherit' }}
                  >
                    Settings
                  </a>
                  {" "}page.
                </span>
              }
            >
              <InfoOutlined className={s.infoIcon} fontSize="small" />
            </MuiTooltip>
          )}
        </Text>
      </Text>
    </div>
  );
}
