import React, { useState, useEffect, useCallback } from "react";
import { api } from "../../services/apis/api";
import Header from "../../components/Header";
import TitleCard from "../../components/TitleCard";
import Loader from "../../components/Loader";
import Text from "../../components/Text";
import { Grid } from "@mui/material";
import { SpotifyTimeRange } from "../../services/intervals";
import { SpotifyTimeRangeSelector } from "../../components/SpotifyTimeRangeSelector/SpotifyTimeRangeSelector";
import styles from "./TrackRoast.module.css";
import { RoastLevel } from "../../services/apis/api";
import { Slider, Box, Typography } from "@mui/material";
import { LocalFireDepartment } from "@mui/icons-material";
import { Button, IconButton, Stack, Tooltip } from "@mui/material";
import { 
  Share as ShareIcon,
  Twitter as TwitterIcon,
  Facebook as FacebookIcon,
  Reddit as RedditIcon,
} from "@mui/icons-material";
import { RoastSlideshow } from "./RoastSlideshow";
import { RoastResponse } from "../../services/apis/api";
import { motion } from "framer-motion";
import { StorySlideshow } from "../../components/StorySlideshow/StorySlideshow";
import { NextStepsSlide } from '../../components/NextStepsSlide/NextStepsSlide';

const RoastLevelSelector = ({ value, onChange }: { 
  value: RoastLevel, 
  onChange: (value: RoastLevel) => void 
}) => {
  const [localValue, setLocalValue] = useState<RoastLevel>(value);
  const levels: RoastLevel[] = ['light', 'medium', 'dark', 'burned'];
  
  const levelColors = {
    light: '#91A676',    // Light greenish
    medium: '#CD7F32',   // Bronze/Medium brown
    dark: '#4A3831',     // Dark brown
    burned: '#1A1110'    // Almost black
  } as const;

  // Helper function to get valid index and level
  const getValidLevel = (value: number): RoastLevel => {
    const index = Math.min(
      Math.max(
        Math.round(value / 33.33),
        0
      ),
      levels.length - 1
    );
    return levels[index] as RoastLevel;
  };

  return (
    <Box sx={{ width: '100%', textAlign: 'center' }}>
      <LocalFireDepartment 
        sx={{ 
          fontSize: 40, 
          color: levelColors[localValue],
          mb: 1
        }} 
      />
      <Slider
        value={levels.indexOf(localValue) * 33.33}
        step={null}  // Force snapping to marks
        marks={levels.map((level, i) => ({
          value: i * 33.33,
          label: <Typography 
            style={{ 
              color: levelColors[level],
              textShadow: '0 1px 2px rgba(0,0,0,0.5)',
              fontWeight: 500,
              fontSize: '1rem'
            }}
          >
            {level.charAt(0).toUpperCase() + level.slice(1)}
          </Typography>
        }))}
        min={0}
        max={99.99}  // 3 * 33.33
        onChange={(_, newValue) => {
          const selectedLevel = getValidLevel(newValue as number);
          setLocalValue(selectedLevel);
        }}
        onChangeCommitted={(_, newValue) => {
          const selectedLevel = getValidLevel(newValue as number);
          onChange(selectedLevel);
        }}
        sx={{
          '& .MuiSlider-track': {
            background: 'linear-gradient(to right, #91A676, #CD7F32, #4A3831, #1A1110)',
            border: 'none'
          },
          '& .MuiSlider-rail': {
            opacity: 0.3,
            backgroundColor: '#666'
          },
          '& .MuiSlider-thumb': {
            backgroundColor: levelColors[localValue],
            boxShadow: '0 0 10px rgba(0,0,0,0.3)'
          },
          '& .MuiSlider-mark': {
            backgroundColor: '#fff',
            opacity: 0.3
          },
          '& .MuiSlider-markLabel': {
            fontSize: '1rem',
            marginTop: '8px'
          },
          // Prevent text selection while dragging
          '& .MuiSlider-markLabel span': {
            userSelect: 'none'
          }
        }}
      />
    </Box>
  );
};

const canShare = () => {
  return typeof navigator !== 'undefined' && navigator.share;
};

const handleShare = async (caption: string) => {
  const shareData = {
    title: 'My Spotify Roast | MyDayInMusic',
    text: `${caption}\n\n🎵 spotify.mydayinmusic.com/roast`,
    url: 'https://spotify.mydayinmusic.com/roast'
  };

  if (canShare()) {
    try {
      await navigator.share(shareData);
    } catch (err) {
      console.log('Error sharing:', err);
    }
  }
};

const shareToTwitter = (caption: string) => {
  const text = encodeURIComponent(`${caption}\n\n🎵 spotify.mydayinmusic.com/roast`);
  const url = encodeURIComponent('https://spotify.mydayinmusic.com/roast');
  const hashtags = 'MyDayInMusic';
  window.open(`https://twitter.com/intent/tweet?text=${text}&url=${url}&hashtags=${hashtags}`, '_blank');
};

const shareToFacebook = () => {
  const url = encodeURIComponent('https://spotify.mydayinmusic.com/roast');
  window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, '_blank');
};

const shareToReddit = (caption: string) => {
  const title = encodeURIComponent(`${caption}`);
  const url = encodeURIComponent('https://spotify.mydayinmusic.com/roast');
  window.open(`https://reddit.com/submit?title=${title}&url=${url}`, '_blank');
};

const createRoastSlides = (roastData: RoastResponse['roast']) => [
  {
    id: 'overall',
    title: 'Your Music Taste...',
    background: 'var(--gradient-main)',
    content: (
      <div className={styles.overallRoast}>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          style={{ color: '#E0E0E0' }}
        >
          {roastData.overall_roast}
        </motion.p>
      </div>
    )
  },
  {
    id: 'specifics',
    title: 'Let\'s Break It Down',
    background: 'var(--gradient-themes)',
    content: (
      <div className={styles.specificsContainer}>
        {roastData.specific_roasts.map((roast, index) => (
          <motion.div
            key={index}
            className={styles.roastItem}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.2 }}
          >
            <h3>{roast.target}</h3>
            <p>{roast.roast}</p>
          </motion.div>
        ))}
      </div>
    )
  },
  {
    id: 'memes',
    title: 'The Meme-worthy Moments',
    background: 'var(--gradient-memes)',
    content: (
      <div className={styles.memesContainer}>
        {roastData.meme_worthy_moments.map((moment, index) => (
          <motion.div
            key={index}
            className={styles.memeItem}
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: index * 0.2 }}
          >
            <p>{moment}</p>
          </motion.div>
        ))}
      </div>
    )
  },
  {
    id: 'redeeming',
    title: 'Silver Linings',
    background: 'var(--gradient-insights)',
    content: (
      <div className={styles.redeemingContainer}>
        {roastData.redeeming_qualities.map((quality, index) => (
          <motion.div
            key={index}
            className={styles.qualityItem}
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: index * 0.2 }}
          >
            <p>{quality}</p>
          </motion.div>
        ))}
      </div>
    )
  },
  NextStepsSlide({
    title: 'Want to see more?',
    description: 'Check out your most listened songs!',
    buttonText: 'VIEW MOST LISTENED SONGS',
    buttonEmoji: '🎵',
    linkTo: api.publicToken ? `/top/songs?token=${api.publicToken}` : '/top/songs'
  })
];

const roastTimeLabels: Record<SpotifyTimeRange, string> = {
  short_term: "Fresh off the Grill 🥩",
  medium_term: "Slow Cooked 🍖",
  long_term: "Well Seasoned 🔥"
};

export default function TrackRoast() {
  const [roastData, setRoastData] = useState<RoastResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [timeRange, setTimeRange] = useState<SpotifyTimeRange>("long_term");
  const [roastLevel, setRoastLevel] = useState<RoastLevel>("medium");
  const [showSlideshow, setShowSlideshow] = useState(false);

  const handleShareCallback = useCallback(() => {
    if (roastData?.roast?.sharing_caption) {
      handleShare(roastData.roast.sharing_caption);
    }
  }, [roastData]);

  const fetchRoast = async () => {
    try {
      setLoading(true);
      setError(null);
      const { data } = await api.getRoast(timeRange, roastLevel);
      setRoastData(data);
      setShowSlideshow(true);
      setLoading(false);
    } catch (err) {
      setError("Failed to roast your music taste. Maybe it's already burned? 🔥");
      setLoading(false);
    }
  };

  if (error) return <div className="text-red-500 p-4">{error}</div>;

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <Loader />
        <Text className={styles.loadingMessage}>
          Preparing your roast... This might burn a little 🔥
        </Text>
        <Text className={styles.loadingSubtext}>
          Our AI is sharpening its wit...
        </Text>
      </div>
    );
  }

  if (showSlideshow && roastData) {
    return (
      <StorySlideshow
        slides={createRoastSlides(roastData.roast)}
        onClose={() => setShowSlideshow(false)}
        onShare={handleShareCallback}
      />
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.backgroundAnimation}>
        {[...Array(20)].map((_, i) => (
          <div 
            key={i} 
            className={styles.ember}
            style={{
              left: `${Math.random() * 100}%`,
              animationDelay: `${Math.random() * 3}s`
            }}
          />
        ))}
      </div>

      <div className={styles.header}>
        <Header
          title="The Musical Grill Master 🔥"
          subtitle="Let our AI chef roast your music taste to perfection"
          right={
            <SpotifyTimeRangeSelector
              value={timeRange}
              onChange={setTimeRange}
              timeRangeLabels={roastTimeLabels}
            />
          }
          timeSelectorType="none"
        />
      </div>
      
      <motion.div 
        className={styles.roastLevelContainer}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
      >
        <RoastLevelSelector value={roastLevel} onChange={setRoastLevel} />
      </motion.div>

      <motion.div 
        className={styles.startButton}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3 }}
      >
        <Button 
          variant="contained"
          size="large"
          onClick={fetchRoast}
          className={styles.roastButton}
        >
          <motion.span
            initial={{ opacity: 0.8 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5, repeat: Infinity, repeatType: "reverse" }}
          >
            Fire Up The Grill 🔥
          </motion.span>
        </Button>
      </motion.div>
    </div>
  );
}
