import React, { useState, useEffect } from "react";
import { api } from "../../services/apis/api";
import Header from "../../components/Header";
import TitleCard from "../../components/TitleCard";
import Loader from "../../components/Loader";
import Text from "../../components/Text";
import type { InsightsResponse } from '../../services/apis/api';
import styles from "./TrackInsights.module.css";
import { Grid } from "@mui/material";
import { SpotifyTimeRange } from "../../services/intervals";
import { SpotifyTimeRangeSelector } from "../../components/SpotifyTimeRangeSelector/SpotifyTimeRangeSelector";
import { StorySlideshow } from "../../components/StorySlideshow/StorySlideshow";
import { motion } from "framer-motion";
import { Button } from '@mui/material';
import { NextStepsSlide } from '../../components/NextStepsSlide/NextStepsSlide';

const RETRY_DELAY = 3000; // 3 seconds
const MAX_RETRIES = 20; // Stop after about 1 minute

export const generateLoadingMessage = () => {
  const messages = [
    // Personal Journey & Discovery
    "Crafting your musical autobiography...",
    "Mapping the constellations of your sonic universe...",
    "Uncovering the chapters of your audio diary...",
    "Weaving the tapestry of your musical memories...",
    "Charting your melodic milestones...",
    "Sketching your sonic self-portrait...",
    "Tracing the footprints of your musical journey...",
    "Assembling your audio anthology...",
    
    // Emotional Analysis
    "Reading the emotional notes between the lines...",
    "Decoding the feelings hidden in your playlists...",
    "Translating your heartbeats into melodies...",
    "Measuring the emotional wavelengths of your songs...",
    "Calculating the sentiment in your symphonies...",
    "Extracting the essence of your emotions...",
    "Distilling the feelings from your frequencies...",
    "Mapping your mood through melodies...",
    
    // AI & Technology
    "Training our AI to understand your unique rhythm...",
    "Synchronizing our neural networks with your beats...",
    "Processing your personal symphony patterns...",
    "Calibrating our musical mind-readers...",
    "Teaching algorithms to appreciate your taste...",
    "Upgrading our melodic machine learning...",
    "Fine-tuning our harmonic neural networks...",
    "Optimizing our sonic algorithms...",
    
    // Book & Cross-Media References
    "Writing the soundtrack to your story...",
    "Bookmarking the chapters of your musical journey...",
    "Composing your personal playlist prose...",
    "Binding your musical memories into volumes...",
    "Penning your audio autobiography...",
    "Illustrating your sonic story...",
    "Drafting your melodic manuscript...",
    "Archiving your acoustic adventures...",
    
    // Data & Insights
    "Calculating your happiness in beats per minute...",
    "Converting frequencies into feelings...",
    "Quantifying your musical quotient...",
    "Mining for melodic memories...",
    "Analyzing your audio algorithms...",
    "Processing your playlist patterns...",
    "Decoding your digital harmonies...",
    "Extracting insights from your earworms...",
    
    // Personal Growth
    "Growing your musical mindfulness...",
    "Cultivating your sonic self-awareness...",
    "Nurturing your audio intelligence...",
    "Expanding your musical consciousness...",
    "Elevating your acoustic awareness...",
    "Developing your harmonic horizons...",
    "Enhancing your melodic mindset...",
    "Broadening your musical boundaries...",
    
    // Fun & Playful
    "Teaching AI to dance to your rhythm...",
    "Tuning our algorithms to your frequency...",
    "Preparing your daily dose of musical wisdom...",
    "Brewing your personal music potion...",
    "Mixing your musical medicine...",
    "Cooking up your sonic smoothie...",
    "Crafting your audio elixir...",
    "Blending your beat bouquet...",
    
    // Time & Temporal
    "Timestamping your musical moments...",
    "Chronicling your sonic seasons...",
    "Archiving your audio adventures...",
    "Preserving your playlist memories...",
    "Capturing your melodic milestones...",
    "Recording your rhythmic journey...",
    "Documenting your daily soundtrack...",
    "Logging your listening legacy...",
    
    // Social & Sharing
    "Preparing your musical story to share...",
    "Crafting your sonic social profile...",
    "Curating your collaborative playlist...",
    "Building your musical community...",
    "Connecting your melodic network...",
    "Harmonizing your social circles...",
    "Orchestrating your shared experiences...",
    "Synchronizing your social soundtrack...",
    
    // Discovery & Recommendations
    "Exploring your musical universe...",
    "Charting new sonic territories...",
    "Mapping your melodic horizons...",
    "Discovering hidden harmonic gems...",
    "Unearthing musical treasures...",
    "Revealing sonic surprises...",
    "Finding your next favorite song...",
    "Predicting your future favorites...",
    
    // Technical & Audio
    "Analyzing waveform patterns...",
    "Processing audio fingerprints...",
    "Calculating harmonic frequencies...",
    "Measuring melodic wavelengths...",
    "Evaluating sonic signatures...",
    "Examining acoustic patterns...",
    "Studying sound spectrums...",
    "Decoding audio DNA...",
    
    // Artistic & Creative
    "Painting with sound waves...",
    "Sculpting your sonic landscape...",
    "Sketching your audio portrait...",
    "Coloring your musical canvas...",
    "Designing your melodic masterpiece...",
    "Crafting your acoustic artwork...",
    "Composing your sound story...",
    "Creating your harmonic palette..."
  ];

  return messages[Math.floor(Math.random() * messages.length)];
};

const insightTimeLabels: Record<SpotifyTimeRange, string> = {
  short_term: "Recent Vibes",
  medium_term: "Steady Rotation",
  long_term: "All-Time Classics"
};

export default function TrackInsights() {
  const [insights, setInsights] = useState<InsightsResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [timeRange, setTimeRange] = useState<SpotifyTimeRange>("long_term");
  const [showSlideshow, setShowSlideshow] = useState(false);

  const fetchInsights = async () => {
    try {
      setLoading(true);
      setError(null);
      const { data } = await api.getTrackInsights(timeRange);
      setInsights(data);
      setShowSlideshow(true);
      setLoading(false);
    } catch (err) {
      setError("Failed to get insights. Please try again.");
      setLoading(false);
    }
  };

  const createInsightSlides = (insights: InsightsResponse) => [
    {
      id: 'summary',
      title: 'Your Music Profile',
      background: 'var(--gradient-main)',
      content: (
        <div className={styles.summary}>
          <Text>{insights.taste_profile.summary}</Text>
        </div>
      )
    },
    {
      id: 'themes',
      title: 'Dominant Themes',
      background: 'var(--gradient-themes)',
      content: (
        <div className={styles.themesContainer}>
          {insights.thematic_analysis.dominant_themes.map((theme, i) => (
            <motion.div
              key={i}
              className={styles.insightItem}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: i * 0.1 }}
            >
              <Text>{theme}</Text>
            </motion.div>
          ))}
        </div>
      )
    },
    {
      id: 'characteristics',
      title: 'Musical Style',
      background: 'var(--gradient-insights)',
      content: (
        <div className={styles.characteristicsContainer}>
          {insights.thematic_analysis.musical_characteristics.map((characteristic, i) => (
            <motion.div
              key={i}
              className={styles.insightItem}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: i * 0.1 }}
            >
              <Text>{characteristic}</Text>
            </motion.div>
          ))}
        </div>
      )
    },
    {
      id: 'eras',
      title: 'Musical Timeline',
      background: 'var(--gradient-memes)',
      content: (
        <div className={styles.erasContainer}>
          {insights.thematic_analysis.era_breakdown.map((era, i) => (
            <motion.div
              key={i}
              className={styles.insightItem}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: i * 0.1 }}
            >
              <Text>{`${era.era}: ${era.percentage}%`}</Text>
            </motion.div>
          ))}
        </div>
      )
    },
    {
      id: 'genres',
      title: 'Genre Insights',
      background: 'var(--gradient-themes)',
      content: (
        <div className={styles.genresContainer}>
          {insights.thematic_analysis.genre_insights.map((genre, i) => (
            <motion.div
              key={i}
              className={styles.insightItem}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: i * 0.1 }}
            >
              <Text>{genre}</Text>
            </motion.div>
          ))}
        </div>
      )
    },
    {
      id: 'distinctive',
      title: 'Your Distinctive Elements',
      background: 'var(--gradient-insights)',
      content: (
        <div className={styles.distinctiveContainer}>
          {insights.taste_profile.distinctive_elements.map((element, i) => (
            <motion.div
              key={i}
              className={styles.insightItem}
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: i * 0.1 }}
            >
              <Text>{element}</Text>
            </motion.div>
          ))}
        </div>
      )
    },
    {
      id: 'discoveries',
      title: 'Recommended Discoveries',
      background: 'var(--gradient-insights)',
      content: (
        <div className={styles.discoveriesContainer}>
          {insights.taste_profile.suggested_discoveries.map((discovery, index) => (
            <motion.div
              key={index}
              className={styles.discoveryItem}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <Text className={styles.discoveryArtist}>
                {discovery.artist_or_genre}
              </Text>
              <Text className={styles.discoveryReason}>
                {discovery.reason}
              </Text>
            </motion.div>
          ))}
        </div>
      )
    },
    {
      id: 'surprise',
      title: 'Surprising Observation',
      background: 'var(--gradient-themes)',
      content: (
        <div className={styles.surpriseContainer}>
          <motion.div
            className={styles.insightItem}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            <Text>{insights.taste_profile.surprising_observation}</Text>
          </motion.div>
        </div>
      )
    },
    NextStepsSlide({
      title: 'Ready for more?',
      description: 'Now that you\'ve seen your insights,\ndare to get roasted?',
      buttonText: 'GET ROASTED',
      buttonEmoji: '🔥',
      linkTo: api.publicToken ? `/roast?token=${api.publicToken}` : '/roast'
    })
  ];

  const handleShare = async () => {
    if (insights?.taste_profile.summary) {
      try {
        // Try Web Share API first
        if (navigator.share) {
          await navigator.share({
            title: 'My Music Insights',
            text: insights.taste_profile.summary,
            url: window.location.href,
          });
        } else {
          // Fallback to Clipboard API with proper permissions
          try {
            await navigator.clipboard.writeText(insights.taste_profile.summary);
            // Optionally show a success toast/message
            alert('Copied to clipboard!');
          } catch (clipboardError) {
            console.error('Clipboard write failed:', clipboardError);
            // Fallback for older browsers or if permission denied
            const textarea = document.createElement('textarea');
            textarea.value = insights.taste_profile.summary;
            document.body.appendChild(textarea);
            textarea.select();
            try {
              document.execCommand('copy');
              alert('Copied to clipboard!');
            } catch (e) {
              console.error('Copy failed:', e);
              alert('Failed to copy to clipboard');
            }
            document.body.removeChild(textarea);
          }
        }
      } catch (error) {
        console.error('Share failed:', error);
        alert('Failed to share');
      }
    }
  };

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <Loader />
        <Text className={styles.loadingMessage}>
          {generateLoadingMessage()}
        </Text>
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 p-4">{error}</div>;
  }

  if (showSlideshow && insights) {
    return (
      <StorySlideshow
        slides={createInsightSlides(insights)}
        onClose={() => setShowSlideshow(false)}
        onShare={handleShare}
      />
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.backgroundAnimation}>
        <div className={styles.circle}></div>
        <div className={styles.circle}></div>
        <div className={styles.circle}></div>
      </div>
      
      <div className={styles.wave} />
      
      <div className={styles.musicalNotes}>
        {[...Array(5)].map((_, i) => (
          <div key={i} className={styles.note} />
        ))}
      </div>
      
      <Header
        title="Your Musical Soul Scanner 🎵"
        subtitle="Let our AI put on its dancing shoes and analyze your beats"
        right={
          <SpotifyTimeRangeSelector
            value={timeRange}
            onChange={setTimeRange}
            timeRangeLabels={insightTimeLabels}
          />
        }
        timeSelectorType="none"
      />

      <motion.div 
        className={`${styles.startButton} ${styles.pulsingButton}`}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3 }}
      >
        <div className={styles.equalizer}>
          {[...Array(5)].map((_, i) => (
            <div key={i} className={styles.bar} />
          ))}
        </div>
        
        <Button 
          variant="contained"
          size="large"
          onClick={fetchInsights}
          className={styles.viewButton}
        >
          <motion.span
            initial={{ opacity: 0.8 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5, repeat: Infinity, repeatType: "reverse" }}
          >
            Generate Insights
          </motion.span>
        </Button>
      </motion.div>

      <div className="sparkles">
        <div className="sparkle"></div>
        <div className="sparkle"></div>
        <div className="sparkle"></div>
        <div className="sparkle"></div>
        <div className="sparkle"></div>
        <div className="sparkle"></div>
        <div className="sparkle"></div>
        <div className="sparkle"></div>
      </div>

      <div className="wave"></div>
    </div>
  );
}

