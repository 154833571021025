import { Settings, SettingsOutlined } from "@mui/icons-material";
import {
  SelectProps,
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import React, { useState, useCallback, useMemo } from "react";
import { useMobile } from "../../services/hooks/hooks";
import {
  allIntervals,
  getAllIndexFromIntervalDetail,
  IntervalDetail,
  shouldDisplayInterval,
} from "../../services/intervals";
import Text from "../Text";
import s from "./index.module.css";
import { useSelector } from "react-redux";
import { selectUser } from "../../services/redux/modules/user/selector";
import ImportNotice from "../ImportNotice/ImportNotice";

interface IntervalSelectorProps {
  value: IntervalDetail;
  onChange: (newDetails: IntervalDetail) => void;
  selectType?: SelectProps["variant"];
  forceTiny?: boolean;
}

export function IntervalSelector({
  value,
  onChange,
  selectType,
  forceTiny,
}: IntervalSelectorProps) {
  const upmd = !useMobile()[1] && !forceTiny;
  const user = useSelector(selectUser);

  const applicableIntervals = useMemo(() => {
    if (!user?.firstListenedAt) return allIntervals;
    const firstListenedAt = new Date(user.firstListenedAt);
    return allIntervals.filter(interval => 
      interval.type === "preset" ? shouldDisplayInterval(interval, firstListenedAt) : true
    );
  }, [user]);

  if (applicableIntervals.length === 1 && applicableIntervals[0]?.name === "Last 24 hours") {
    return null;
  }

  const existingInterval = useMemo(
    () => getAllIndexFromIntervalDetail(value),
    [value],
  );

  const internOnChange = useCallback(
    (index: number) => {
      const interval = applicableIntervals[index];
      if (!interval) {
        return;
      }
      onChange(interval);
    },
    [onChange, applicableIntervals],
  );

  let content: React.ReactNode;

  if (!upmd) {
    content = (
      <>
        <ImportNotice />
        <Select
          variant={selectType}
          value={existingInterval}
          onChange={ev => internOnChange(ev.target.value as number)}>
          {applicableIntervals.map((inter, index) => (
            <MenuItem key={inter.name} value={index}>
              {inter.name}
            </MenuItem>
          ))}
        </Select>
      </>
    );
  } else {
    content = (
      <div className={s.radiogroup}>
        <ImportNotice />
        <RadioGroup
          row
          value={existingInterval}
          onChange={ev => internOnChange(ev.target.value as unknown as number)}
          name="interval radio group">
          {applicableIntervals.map((inter, index) => (
            <FormControlLabel
              key={inter.name}
              value={index}
              control={<Radio />}
              label={<Text>{inter.name}</Text>}
            />
          ))}
        </RadioGroup>
      </div>
    );
  }

  return <>{content}</>;
}
