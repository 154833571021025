import posthog, { PostHogConfig } from 'posthog-js'

// Initialize PostHog with your project API key and host URL
export const initPostHog = () => {
  // Default config with correct types
  const config: Partial<PostHogConfig> = {
    api_host: 'https://us.i.posthog.com',
    person_profiles: 'identified_only' as const,
    autocapture: true,
    capture_pageview: true,
    // Disable session recording using the correct property
    disable_session_recording: false
  }

  // Get user from localStorage or other source
  const user = localStorage.getItem('spotify_user')
  const parsedUser = user ? JSON.parse(user) : null
  
  // Disable tracking for specific user
  if (parsedUser?.id === 'crarau') {
    config.autocapture = false
    config.capture_pageview = false
    config.disable_session_recording = true
  }

  posthog.init('phc_NaegtA8uWJ37hqG0XTfqtwNzUX4YXJHgHoicJGU7tCZ', config)
}

export const identify = (userId: string, properties?: Record<string, any>) => {
  // Only identify if not the excluded user
  if (userId !== 'crarau') {
    posthog.identify(userId, properties)
  }
}

export const capture = (eventName: string, properties?: Record<string, any>) => {
  // Only capture events if not the excluded user
  const user = localStorage.getItem('spotify_user')
  const parsedUser = user ? JSON.parse(user) : null
  
  if (parsedUser?.id !== 'crarau') {
    posthog.capture(eventName, properties)
  }
}

export default posthog
