import React from 'react';
import { motion } from 'framer-motion';
import { Button } from '@mui/material';
import Text from '../Text';
import styles from './NextStepsSlide.module.css';

interface NextStepsSlideProps {
  title: string;
  description: string;
  buttonText: string;
  buttonEmoji: string;
  linkTo: string;
}

export const NextStepsSlide = ({ 
  title, 
  description, 
  buttonText, 
  buttonEmoji, 
  linkTo 
}: NextStepsSlideProps) => {
  return {
    id: 'next-steps',
    title: title,
    background: 'var(--gradient-main)',
    content: (
      <div className={styles.nextStepsContainer}>
        <motion.div
          className={styles.nextStepsContent}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          <Text className={styles.nextStepsText}>
            {description.split('\n').map((line, i) => (
              <React.Fragment key={i}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </Text>
          <Button
            className={styles.nextStepsButton}
            variant="contained"
            onClick={() => {
              window.location.href = linkTo;
            }}
          >
            {buttonText} {buttonEmoji}
          </Button>
        </motion.div>
      </div>
    )
  };
};
