import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import Header from "../../components/Header";
import History from "../../components/History";
import ArtistsListened from "../../components/ImplementedCards/ArtistsListened";
import BestArtist from "../../components/ImplementedCards/BestArtist";
import BestSong from "../../components/ImplementedCards/BestSong";
import SongsListened from "../../components/ImplementedCards/SongsListened";
import TimeListened from "../../components/ImplementedCards/TimeListened";
import ListeningRepartition from "../../components/ImplementedCharts/ListeningRepartition";
import TimeListenedPer from "../../components/ImplementedCharts/TimeListenedPer";
import { selectUser } from "../../services/redux/modules/user/selector";
import s from "./index.module.css";
import { useNavigate } from "react-router-dom";
import { api } from "../../services/apis/api";
import { loadStripe } from "../../services/stripe";
import { useEffect } from "react";

export default function Home() {
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  useEffect(() => {
    const refreshData = async () => {
      try {
        const { data } = await api.refreshHistory();
        if (data.newTracks > 0) {
          window.location.reload();
        }
      } catch (error) {
        console.error('Failed to refresh history:', error);
      }
    };

    if (user) {
      refreshData();
    }
  }, [user]);

  if (!user) {
    return null;
  }

  return (
    <div>
      <Header
        title={`Welcome, ${user.username} 🎉`}
        tinyTitle="Welcome"
        subtitle=""
      />
      <div className={s.content}>
        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={12} md={12} lg={4}>
            <SongsListened />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TimeListened />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ArtistsListened />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <TimeListenedPer className={s.timelisten} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <BestArtist />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <ListeningRepartition className={s.timelisten} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <BestSong />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <History />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}