import { Button } from "@mui/material";
import { useCallback } from "react";
import TitleCard from "../../../components/TitleCard";
import SettingLine from "../SettingLine";
import { api } from "../../../services/apis/api";
import { useAppDispatch } from "../../../services/redux/tools";
import { alertMessage } from "../../../services/redux/modules/message/reducer";

export default function TestButtons() {
  const dispatch = useAppDispatch();

  const handleTestEmail = useCallback(async (type: 'yesterday' | 'today') => {
    try {
      const { data } = await api.testDailyEmail(type);
      dispatch(
        alertMessage({
          level: "success",
          message: data.message,
        })
      );
    } catch (error) {
      dispatch(
        alertMessage({
          level: "error",
          message: `Failed to send test ${type}'s summary`,
        })
      );
    }
  }, [dispatch]);

  return (
    <TitleCard title="Test Summary">
      <SettingLine
        left="Test summaries"
        right={
          <div style={{ display: 'flex', gap: '8px' }}>
            <Button 
              variant="contained" 
              onClick={() => handleTestEmail('yesterday')}
            >
              Test Yesterday's Summary
            </Button>
            <Button 
              variant="contained" 
              onClick={() => handleTestEmail('today')}
            >
              Test Today's Summary
            </Button>
          </div>
        }
      />
    </TitleCard>
  );
}
